import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  products = [
    {
      id: 1,
      title: 'Automotive industry',
      subtitle: '',
      description: `Carbon fibers are revolutionizing the automotive industry.
      Carbon fiber reinforced polymer (CFRP) finds the greatest
      use in high end automobiles. Many automotive
      manufacturers are turning to carbon fiber for increasing
      the efficiency and value-structure of their processes. The
      material has a very high strength-to-weight ratio and is
      essentially carbon fiber bounded by polymer such as
      epoxy resin. The density is as low as 1.6g/cc, which
      makes it extremely lightweight as opposed to steel
      without compromising on strength. MALU Group offers
      assistance to companies to develop their carbon fiber
      composite in their automotive industry .
      MALU Group provides the low-cost, high-performance
      carbon fiber And offers technology transfer needed for
      application into Automotive industry`,
      link: '',
      icon: 'assets/images/products/005-car-1.svg',
      image: 'https://malugroupindia.com/images/applications/Automotive/image1.jpg',
      gallery: [
        {
          url: 'https://malugroupindia.com/images/applications/Automotive/image1.jpg',
          desc: 'Carbon fiber roof for BMW car (carbon fiber composite molding techniques)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image2.jpg',
          desc: 'Carbon composite body (high performance cars )'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image3.jpg',
          desc: 'Carbon fiber motorcycle pannier'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image4.jpg',
          desc: 'Kevlar and Carbon Fiber Rim'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image5.png',
          desc: 'Carbon fiber seat (high strength + decorative material) in car'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image6.jpg',
          desc: 'Carbon fiber rim'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image7.jpg',
          desc: 'Carbon fiber car chassis'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image8.jpg',
          desc: 'Carbon fiber heat sink and engine protective case'
        }, {
          url: 'https://malugroupindia.com/images/applications/Automotive/image9.jpg',
          desc: 'Carbon fiber composite body (carbon fiber+aramid+glass) fuel efficient more reliable in combat zone'
        }
      ]
    }, {
      id: 2,
      title: 'Aerospace industry',
      subtitle: '',
      description: `It is long ago already estabilished that use of composite in
      aerospace industry. From past few years to avoide the
      overhead prizes and more efficient composits are
      commonly used. From Aerospace vehicle structure to
      conducting components are build with carbon fibre, other
      technical textile and their composites
      MALU group has long exposure ans experience in
      commercial to special purpose vehicle building with
      carbon composites`,
      link: '',
      icon: 'assets/images/products/002-airplane.svg',
      image: 'https://malugroupindia.com/images/applications/Aerospace/image1.jpg',
      gallery: [
        {
          url: 'https://malugroupindia.com/images/applications/Aerospace/image1.jpg',
          desc: 'Dron made up of glass fiber composite (weight reduction upto 40%, longer battery life and range)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Aerospace/image2.jpg',
          desc: 'Large carbon composite fuel tank for space shuttle'
        }, {
          url: 'https://malugroupindia.com/images/applications/Aerospace/image3.jpg',
          desc: 'Aviation body part carbon composite'
        }
      ]
    },
    {
      id: 3,
      title: 'Wind energy',
      subtitle: '',
      description: `Carbon fiber is used primarily in the spar, or structural
      element, of wind blades both for land-based and offshore
      systems. The higher stiffness and lower density of CF
      allows a thinner blade profile while producing stiffer,
      lighter blades. The analysis found commercial viability
      and system-level benefits for using carbon fiber
      composites to reduce the overall cost of wind energy and
      manufacture long, slender wind turbine blades. It is
      revealed a 25% blade mass reduction when using carbon
      fiber spar caps compared to fiberglass.
      MALU Group has developed significant expertise in
      building wind blade structural composites with carbon
      fiber.<br/>
      MALU Group provides the low-cost, high-performance
      carbon fiber And offers technology transfer needed for
      application into Wind energy`,
      link: '',
      icon: 'assets/images/products/006-windmill.svg',
      image: '',
      gallery: [
        {
          url: 'https://malugroupindia.com/images/applications/Wind/image2.png',
          desc: 'Structure manufacturing (carbon fabric + glass fabric)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Wind/image3.jpg',
          desc: 'Carbon blades'
        },
        {
          url: 'https://malugroupindia.com/images/applications/Wind/image1.gif',
          desc: ''
        }
      ]

    }, {
      id: 4,
      title: 'Construction industry',
      subtitle: '',
      description: `Carbon-fibre, has been used in a wide range of applications to provide reinforcement to existing structures. 
      Concrete reinforcement with carbon fibre sheet straps is a time-proven and tested method of reinforcing existing concrete and 
      masonry structures for the purposes of repair or to strengthen the integrity and load tolerance of existing structures. 
      The fabrics are saturated with epoxy and applied to the concrete structure using a wet layup process known as fiber 
      reinforced polymer (FRP) systems.<br/>MALU Group provides the low-cost, high-performance carbon fiber and 
      offers technology transfer needed for application into civil industries.`,
      link: '',
      icon: 'assets/images/products/009-brickwall.svg',
      image: '',
      gallery: [
         {
          url: 'https://malugroupindia.com/images/applications/Construction/image2.jpg',
          desc: 'Roof strengthening'
        }, {
          url: 'https://malugroupindia.com/images/applications/Construction/image3.jpg',
          desc: 'Pillar strengthening'
        }, {
          url: 'https://malugroupindia.com/images/applications/Construction/image4.jpg',
          desc: 'Pillar  reinforcement for long life'
        }, {
          url: 'https://malugroupindia.com/images/applications/Construction/image5.jpg',
          desc: 'Carbon fiber bar (different sizes)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Construction/image6.png',
          desc: 'Carbon fiber rebar'
        },{
          url: 'https://malugroupindia.com/images/applications/Construction/image1.jpg',
          desc: 'Carbon Fiber Bars'
        }
      ]
    }, {
      id: 5,
      title: 'Marine applications',
      subtitle: '',
      description: `As (CFRP) has come of age, so more and more
      applications have been found that take advantage of its
      exceptional properties. Key fixtures and fittings of the
      boat are now being made from these materials. Steering
      wheels and wind transducers are some of the most recent
      advances. The high specific stiffness of carbon fiber lends
      itself well to use in applications such as masts, hulls and
      propellers gives enhanced flue efficiency and speed
      MALU Group provides the low-cost, high-performance
      carbon fiber And offers technology transfer needed for
      application into Marine business`,
      link: '',
      icon: 'assets/images/products/010-rope.svg',
      image: '',
      gallery: [
        {
          url: 'https://malugroupindia.com/images/applications/Marine/image1.jpg',
          desc: 'Carbon fiber protective decorative part'
        }, {
          url: 'https://malugroupindia.com/images/applications/Marine/image2.jpg',
          desc: 'Carbon fiber water propeller'
        }, {
          url: 'https://malugroupindia.com/images/applications/Marine/image3.jpg',
          desc: 'Carbon fiber decorative part (storage)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Marine/image4.jpg',
          desc: 'Carbon fiber propeller'
        }, {
          url: 'https://malugroupindia.com/images/applications/Marine/image5.jpg',
          desc: 'Carbon fiber body (speed boat)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Marine/image6.jpg',
          desc: 'Carbon fiber jet skis'
        }
      ]
    }, {
      id: 6,
      title: 'Sport',
      subtitle: '',
      description: `Pro cyclists can now race faster for longer distances,
      golfers can swing easier and with much better precision,
      tennis players are able to have more versatile moves and
      have better control of their rackets, while board skaters
      can now do more mind-boggling stunts.
      carbon fibers are highly preferred for the manufacture of
      sports equipment like sports sticks and rackets, primarily
      due to their advanced properties such as light weight, high
      strength to weight ratio, improved resistance, and
      stiffness.<br/>
      MALU Group provides the low-cost, high-performance
      carbon fiber And offers technology transfer needed for
      application into sports industries`,
      link: '',
      icon: 'assets/images/products/bike.svg',
      image: 'https://malugroupindia.com/images/applications/Sports/image1.jpg',
      gallery: [
        {
          url: 'https://malugroupindia.com/images/applications/Sports/image1.jpg',
          desc: 'Carbon fiber reinforced joints'
        }, {
          url: 'https://malugroupindia.com/images/applications/Sports/image2.jpg',
          desc: 'Carbon fiber handle bar'
        }, {
          url: 'https://malugroupindia.com/images/applications/Sports/image3.jpg',
          desc: 'Carbon fiber joint'
        }, {
          url: 'https://malugroupindia.com/images/applications/Sports/image4.jpg',
          desc: 'Complete composite composite bicycle'
        }, {
          url: 'https://malugroupindia.com/images/applications/Sports/image5.jpg',
          desc: 'Carbon composite bicycle'
        }
      ]
    }, {
      id: 7,
      title: 'Carbon composite',
      subtitle: '',
      description: `Carbon fiber reinforced polymers are advanced
      composite materials used in a wide range of
      applications, from aerospace to automotive to sports
      equipment. When bound with plastic polymer resin,
      carbon fiber creates a composite material that is
      extremely strong, durable and lightweight and can be
      found in many forms, including fabrics, tubes and
      tows.<br/>
      MALU group have wide experience in composite and
      we provide good composite material with technology
      as required`,
      link: '',
      icon: '/assets/images/products/chemical-composition.svg',
      image: 'https://malugroupindia.com/images/applications/Composite/image1.jpg',
      gallery: [
        {
          url: 'https://malugroupindia.com/images/applications/Composite/image1.jpg',
          desc: 'Carbon composite (before process)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Composite/image2.jpg',
          desc: 'Carbon composite (after process)'
        }, {
          url: 'https://malugroupindia.com/images/applications/Composite/image3.jpg',
          desc: 'Carbon composite sheets'
        }, {
          url: 'https://malugroupindia.com/images/applications/Composite/image4.png',
          desc: 'Carbon composite molding process'
        }
      ]
    },
    {
      id: 8,
      title: 'Environmental Applications',
      subtitle: '',
      description: `As a chemical purifier, carbon is a powerful absorbent. When it comes to the absorption of noxious or unpleasant
      chemicals, then the surface area is important. For a given weight of carbon, thin filaments have far more
      surface area than granules. Although we see activated carbon granules used as pet litter and for water
      purification, the potential for wider environmental use is clear.`,
      link: '',
      icon: '/assets/images/products/plant.svg',
      image: '',
      gallery: [
      ]
    },
    {
      id: 9,
      title: 'Medical Applications',
      subtitle: '',
      description: `Carbon fiber offers several advantages over other materials in the medical field, including the fact that it is
      ‘radiolucent’ – transparent to X-rays and shows as black on X-ray images. It is used widely in imaging equipment
      structures to support limbs being X-rayed or treated with radiation.<br/>
      The use of carbon fiber to strengthen damaged cruciate ligaments in the knee is being researched, but probably
      the most well known medical use is that of prosthetics – artificial limbs. South African athlete Oscar Pistorius
      brought carbon fiber limbs to prominence when the International Association of Athletics Federations failed to
      ban him from competing in the Beijing Olympics. His controversial carbon fiber right leg was said to give him an
      unfair advantage, and there is still considerable debate about this.`,
      link: '',
      icon: '/assets/images/products/splint.svg',
      image: '',
      gallery: [
      ]
    }
    ,{
      id: 10,
      title: 'Fabric',
      subtitle: '',
      description: ``,
      link: '',
      icon: '/assets/images/products/chemical-composition.svg',
      image: 'https://malugroupindia.com/images/applications/Fabric/image1.jpg',
      gallery: [
        {
          url: 'https://malugroupindia.com/images/applications/Fabric/image1.jpg',
          desc: 'Aramid(peak)+carbon(end ) hybride fabic 100-750gsm'
        }, {
          url: 'https://malugroupindia.com/images/applications/Fabric/image2.png',
          desc: ''
        }, {
          url: 'https://malugroupindia.com/images/applications/Fabric/image3.jpg',
          desc: 'Carbin (peak)+ aramid(end) twill weave 100-750gsm'
        }, {
          url: 'https://malugroupindia.com/images/applications/Fabric/image4.jpg',
          desc: 'Carbon carbon weave 100-1200 gsm'
        },{
          url: 'https://malugroupindia.com/images/applications/Fabric/image5.jpg',
          desc: 'Carbon (peak)+fiberglass(end) plain weave 100-350gsm'
        }, {
          url: 'https://malugroupindia.com/images/applications/Fabric/image6.jpg',
          desc: 'Aramid +carbon hybrid weave 200-1000gsm'
        }
      ]
    }
  ]

  constructor() { }

  // private selectionFormatState = new Subject<any>();

  // setState(state: any) {
  //   this.selectionFormatState.next(state);
  // }

  // getState(): Observable<any> {
  //   return this.selectionFormatState.asObservable();
  // }
}
