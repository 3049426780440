<div class="home-wrapper" id="home">
    <div class="home-content md-text-center ">
        <div class="home-background container">

            <div class=" home-text home-text1  animated fadeIn faster">
                Welcome to </div>

            <div class="home-text home-text2  animated fadeIn faster">The Malu Group</div>

            <!-- <div class="home-text-about animated fadeIn delay-1s" data-wow-easing="linear" data-wow-delay="1500">About
                Us</div> -->

            <div class="home-text animated fadeIn delay-1s" data-wow-easing="linear" data-wow-delay="1700">
                We are the distributor, exporter, and importer of carbon fibers
                and their products, aramid yarn , high strength
                fiberglass, strengthening and composite materials,
                non woven fabric and many more. The expertise in
                technology leads our firm and our client to achieve new
                levels of performance across a range of products. Using
                the finest quality raw material to manufacture the
                products so as to match their desired level of quality
                standards, stands with international standards with
                consumer satisfaction.</div>

            <div>
                <button type="button" class="btn btn-primary waves-effect contact-us-btn animated bounceIn delay-2s"
                    routerLink="/contactus">Contact Us</button>
            </div>

            <div class="row">
                <div class="col-md-6 mb-md-0 mb-6 home-card first animated fadeInLeft delay-2s">
                    <div class="home-header">
                        <div class="home-header-text">Our Vision</div>
                    </div>
                    <div class="home-body">
                        <div class="home-body-text">
                            To be best partner and obious choice to our customer by provding total technical textile
                            solution
                            through innovative and reliable ways.<br /><br />To grow in steady and responsible pace
                            to a
                            position
                            of strength and promising in our areas of operation.
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-md-0 mb-6 home-card second animated fadeInRight delay-2s">
                    <div class="home-header">
                        <div class="home-header-text">Our Mission</div>
                    </div>
                    <div class="home-body">
                        <div class="home-body-text">
                            Malu group india mission is to provide best quality product in time to enhance the our
                            customers
                            business while providing best technogical support that benefits both our customer and
                            environment.
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>
    <!-- <div class="arrow-down-wrapper" (click)="scroll()" *ngIf="shouldShow">
        <img class="arrow-down" src="assets/images/down.svg" alt="" >
    </div> -->

</div>