<div class="wrapper">
    <div class="container wiki">
        <div>
            Carbo fiber dates back to 1879. Inventor Thomas Edison used carbon fibers as filaments for early light bulbs
            (Hegde, 2004). Although these fibers lacked the tensile strength of today's carbon fibers, their
            considerable
            tolerance to heat made these fibers ideal for conducting electricity. Edison’s carbon fibers were made out
            of
            cellulose-based materials, such as cotton or bamboo, unlike the petroleum-based precursors used today.
            Carbonization took place by baking bamboo filaments at high temperatures in a controlled atmosphere. This is
            a
            method known as "pyrolysis," which is still used today. The resulting carbonized bamboo filaments were
            fire-resistant and capable of enduring the intense heat needed for incandescence.
            <br><br>
            Carbon fiber is made from a combining process that includes chemical and mechanical. It starts by drawing
            long
            strands of fibers and then heating them into nitrogen or argon atmosphere at a very high temperature. This
            is
            when the carbonization takes place at 2800 to 30000c, to remove all impurities. This leaves a fiber composed
            of
            long, tightly bonded chains of carbon atoms with only a few non-carbon atoms remaining.
            Polyacrylonitrile (PAN) is used to form carbon fibers. this process involves spinning, stabilizing,
            carbonizing,
            treating the surface, and sizing. Pitch, coal and other material having high carbon-containing materials are
            also used to form carbon fiber having their own different process  
        </div>
        <hr />

        <div><span class="carbon-section-title">Future Outlook</span></div>
        <p>With ever-increasing fuel costs and environmental lobbying, more industry is under sustained pressure to
            improve
            performance, and weight reduction is a key factor in the equation.
            Beyond the day-to-day operating costs, the maintenance programs can be simplified by component count
            reduction
            and corrosion reduction. The competitive nature of the business ensures that any opportunity to reduce
            operating
            costs is explored and exploited wherever possible.
            Competition exists in the military too, with continuous pressure to increase payload and range, flight
            vehicles
            vest performance characteristics, and 'survivability', 
            Composite technology continues to advance, and the advent of new types such as basalt and carbon nanotube
            forms
            is certain to accelerate and extend composite usage.
            When it comes to automobile aerospace, energy marine construction sports composite materials are here to
            stay.
        </p>
        <hr />

        <!-- <div><span class="carbon-section-title">Daily use of carbon fiber</span></div>
    <div class="chip">Wallet </div>
    <div class="chip"> Knife </div>
    <div class="chip"> Rings </div>
    <div class="chip"> Mobile protection</div>
    <div class="chip"> Music instruments </div>
    <div class="chip"> Helmet</div>
    <div class="chip">Car body parts </div>
    <div class="chip">bike </div>
    <div class="chip"> watch </div>
    <div class="chip"> drone </div>
    <div class="chip">bulletproof armed trucks</div>
    <div class="chip"> composite solutions</div>
    <div class="chip">Bike frames</div>
    <div class="chip">Aircraft Wings</div>
    <div class="chip"> Automotive driveshafts</div>
    <div class="chip">Tubing</div>
    <div class="chip"> Containers</div>
    <div class="chip"> Propeller blades</div>
    <div class="chip"> Car components</div>
    <hr /> -->

        <div><span class="carbon-section-title">Carbon Fiber at Home</span></div>

        <p>The uses of carbon fiber in the home are as broad as your imagination, whether it is style or practical
            application. For those who are style-conscious, it is often tagged as ‘the new black’. If you want a shiny
            black
            bathtub built from carbon fiber or a coffee table then you can have just that, off the shelf. iPhone cases,
            pens, and even bow ties – the look of carbon fiber is unique and attractive.</p>
        <hr />
        <!-- <div><span class="carbon-section-title">Environmental Applications</span></div>

    <p>As a chemical purifier, carbon is a powerful absorbent. When it comes to the absorption of noxious or unpleasant
        chemicals, then the surface area is important. For a given weight of carbon, thin filaments have far more
        surface area than granules. Although we see activated carbon granules used as pet litter and for water
        purification, the potential for wider environmental use is clear.</p>
    <hr />
    <div><span class="carbon-section-title">Medical Applications</span></div>

    <p>Carbon fiber offers several advantages over other materials in the medical field, including the fact that it is
        ‘radiolucent’ – transparent to X-rays and shows as black on X-ray images. It is used widely in imaging equipment
        structures to support limbs being X-rayed or treated with radiation.
        The use of carbon fiber to strengthen damaged cruciate ligaments in the knee is being researched, but probably
        the most well known medical use is that of prosthetics – artificial limbs. South African athlete Oscar Pistorius
        brought carbon fiber limbs to prominence when the International Association of Athletics Federations failed to
        ban him from competing in the Beijing Olympics. His controversial carbon fiber right leg was said to give him an
        unfair advantage, and there is still considerable debate about this.</p>
    <hr /> -->
        <div><span class="carbon-section-title">Carbon Fabrics</span>
            <div class="gallery-link-wrapper">
                <mdb-badge class="align-right" pill="true" default="true"><a class="gallery-link"
                        [routerLink]="['/applications', 10]">View Gallery</a></mdb-badge>
            </div>
        </div>
        <h2>Unidirectional fabrics </h2>
        <p>UD fabric is a type of product in which over 75% of fibers are oriented in the same direction. That allows
            obtaining optimized operational properties for bearing loads in one direction.</p>
        <table mdbTable hover="true">
            <tbody>
                <tr mdbTableCol>
                    <td>Fabric weight</td>
                    <td>100-800 gsm</td>
                </tr>
                <tr mdbTableCol>
                    <td>Fiber used </td>
                    <td>3K, 12K, 24K, 48K, 50K </td>
                </tr>
                <tr mdbTableCol>
                    <td>Suitable resin</td>
                    <td>EP, VE</td>
                </tr>
                <tr mdbTableCol>
                    <td>Weft system</td>
                    <td>glass, aramid, hot melt glass </td>
                </tr>
            </tbody>
        </table>

        <hr />
        <h2>Unidirectional Materials (Non-woven)</h2>
        <p>Carbon yarns in non-woven materials are positioned exactly parallel to each other which enables obtaining
            outstanding mechanical properties.</p>

        <table mdbTable hover="true">
            <tbody>
                <tr mdbTableCol>
                    <td>Fabric weight</td>
                    <td>100-1200 gsm</td>
                </tr>
                <tr mdbTableCol>
                    <td>Fiber used </td>
                    <td>3K, 12K, 24K, 48K, 50K and their short fibers </td>
                </tr>
                <tr mdbTableCol>
                    <td>Suitable resin</td>
                    <td>Epoxy and others</td>
                </tr>
                <tr mdbTableCol>
                    <td>Binding system </td>
                    <td>Epoxy in powder </td>
                </tr>
            </tbody>
        </table>
        <hr />
        <h2>Balanced Woven Fabrics </h2>
        <p>Balanced fabrics are produced from carbon fibers obtained via exposure of organic fibers to high temperatures
            in
            inert media. Carbon materials are resistant to the majority of chemically aggressive reagents, have high
            tensile
            strength characteristics, low coefficient of thermal expansion, low weight. All this allows using fabrics in
            various industries.</p>

        <table mdbTable hover="true">
            <tbody>
                <tr mdbTableCol>
                    <td>Fabric weight</td>
                    <td>100-1000 gsm</td>
                </tr>
                <tr mdbTableCol>
                    <td>Fiber used </td>
                    <td>3K, 12K, 24K, 48K, 50K </td>
                </tr>
                <tr mdbTableCol>
                    <td>Weave Type</td>
                    <td>Plain twill drill steen satin and many more </td>
                </tr>
                <tr mdbTableCol>
                    <td>Resins</td>
                    <td>Epoxy and other </td>
                </tr>
            </tbody>
        </table>
        <hr />
        <h2>Prepregs </h2>
        <p>Prepregs are composite materials in which a reinforcement fiber is pre-impregnated with a thermoplastic or
            thermoset resin matrix. Prepregs have unique properties as they are cured under high temperatures and
            pressures.generally used in the aerospace automotive industry due to high modulus and strength e clean
            process
            UV resistivity and easy to handle.</p>
        <table mdbTable hover="true">
            <tbody>
                <tr mdbTableCol>
                    <td>Fabric weight</td>
                    <td>100–600 gsm</td>
                </tr>
                <tr mdbTableCol>
                    <td>Resin content </td>
                    <td>more than 25% </td>
                </tr>
                <tr mdbTableCol>
                    <td>Fiber type </td>
                    <td>carbon fiber , glass and aramid fiber</td>
                </tr>
                <tr mdbTableCol>
                    <td>Glass transition temperature (Tg)</td>
                    <td>90–200 °C </td>
                </tr>
                <tr mdbTableCol>
                    <td>Reinforcement</td>
                    <td>UD, UD and biaxial woven fabrics, multiaxial fabrics</td>
                </tr>
            </tbody>
        </table>



    </div>
</div>