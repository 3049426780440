import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ApplicationsComponent } from './pages/applications/applications.component';
import { CarbonFiberComponent } from './pages/carbon-fiber/carbon-fiber.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ProductInfoComponent } from './pages/product-info/product-info.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'carbonfiber', component: CarbonFiberComponent },
  { path: 'applications', component: ApplicationsComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: 'applications/:id', component: ProductInfoComponent },
  { path: "**" ,component: HomeComponent, }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
