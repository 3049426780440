import { Component, OnInit, EventEmitter, ElementRef } from '@angular/core';
import { query, stagger,state,animate, style, transition, trigger, useAnimation } from '@angular/animations';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('firstText', [
      transition(':enter', [
        style({ opacity: 0 ,transform: 'translateY(-50%)'}),
        animate('1500ms', style({ opacity: 1,transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('secondText', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms 1500ms', style({ opacity: 1 })),
        
      ]),
    ]),
  ]
})
export class HomeComponent implements OnInit {
  shouldShow=true
  constructor(public CommonService:CommonService,public el:ElementRef) { }

  ngOnInit(): void {
    // this.CommonService.getState().subscribe(data=>{
    //  this.shouldShow=data
    // })
  

  }

  scroll(){
    let element=document.getElementById('integrations')
    element.scrollIntoView()
  }
  ngOnDestroy(){
  }

}
