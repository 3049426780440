import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

  product;

  constructor(private route: ActivatedRoute,public commonService:CommonService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.product = this.commonService.products.find(a=>a.id+''==params.get('id'))
      console.log(this.product)
    })
  }

}
