import { Component, HostListener, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
declare var window;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'MaluGroup';
 scrollEvent;
  constructor(public el:ElementRef){
  
  }

  ngOnInit(){
    
  }



 
  

}
