<h6 class="h5-responsive font-weight-bold mb-1" [ngClass]="contactData.director?'director':''">{{contactData.name}}</h6>
<p class="mb-2" *ngIf="contactData.mobile"><mdb-icon fas icon="phone-alt" class="mr-1"></mdb-icon>{{contactData.mobile}}</p>
<p class="mb-2" *ngIf="!contactData.mobile && !contactData.director" class="dummy-contact"></p>
<p class="mb-2"><mdb-icon fas icon="envelope" class="mr-1"></mdb-icon><a [href]="getContactEmail(contactData)">{{contactData.email}}</a></p>




<!-- <div class="card">
 
    <div class="card-body">
  

      <h4 class="card-title">{{contactData.name}}</h4>

      <p class="card-text" *ngIf="contactData.mobile"><mdb-icon fas icon="phone-alt" class="mr-1"></mdb-icon>{{contactData.mobile}}</p>
      <p class="card-text"><mdb-icon fas icon="envelope" class="mr-1"></mdb-icon><a [href]="getContactEmail(contactData)">{{contactData.email}}</a></p> 

      <a [href]="getContactEmail(contactData)" class="btn btn-primary">Contact</a>
  
    </div>
  
  </div> -->
