<!-- <app-footer></app-footer> -->
<div class="contact-container">
    <div class="row-wrapper">
        <div class="banner">
            <div class="contact-us-text">Contact Us</div>
            <p class="px-5">Our team is happy to answer your sales queries. Get in touch.</p>
        </div>
    </div>

    <div class="row-wrapper contact-wrapper">
        <div class="row">

            <div class="col-md-5 mb-md-0 mb-5 director">


                <!--Header-->
                <div class="row">
                    <div class="col-md-12 mb-md-0 mb-12">
                        <div class="title">Director</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-md-0 mb-12">
                        <app-contact [data]="contacts[0]" class="director-contact"></app-contact>
                    </div>
                </div>


            </div>
            <div class="col-md-7 mb-md-0 mb-7 team">

                <div class="row">
                    <div class="col-md-12 mb-md-0 mb-12">
                        <div class="title">Management</div>
                    </div>
                </div>
                <!--Header-->
                <div class="row">
                    <div class="col-md-6 mb-md-0 mb-6" *ngFor="let contact of contacts.slice(1,3);let i=index">
                        <app-contact [data]="contact"></app-contact>
                    </div>
                    <!--NGFOR-->
                </div>

            </div>

        </div>
        <hr />
        <div class="row justify-content-md-center">
            <div class="col-md-12 mb-md-0 mb-12">
                <div class="title" class="text-center">
                    <mdb-icon fas icon="map-marker-alt" size="2x" class="mr-1"></mdb-icon><span
                        class="address-title">Office Address</span>
                </div>
                <div class="map-wrapper">
                    <div class="row justify-content-md-center">
                        <div class="col-md-6 mb-md-0 mb-6 text-center view overlay zoom image-wrapper">
                            <img src="./assets/images/map.png" />
                            <a href="https://www.google.com/maps/search/?api=1&query=Mahesh+Housing+Society,+Yashwant+Colony,+Ichalkaranji" target="_blank"> <div class="mask flex-center">
                                <p class="primary-text">Click to open in map</p>
                            </div></a>
                        </div>
                    
                    <div class="col-md-6 mb-md-0 mb-6 text-left address">
                            <div>19/674/20, Mahesh co-op housing society, Pratap nagar, Ichalkaranji, Dist. Kolhapur, Maharashtra, India - 416115</div>
                    </div>
                </div>

                    <!-- <div  class="text-center">
                   19/674/20, Mahesh co-op housing society, Pratap nagar, Ichalkaranji, Dist. Kolhapur, Maharashtra, India - 416115
                    </div> -->

                </div>

            </div>
        </div>
    </div>
</div>

