import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
 eventEmitter;
  constructor(public commonService:CommonService) {
    // window.onscroll = () => this.scrollFunction();
  }
  scrollFunction() {
    // const el = <HTMLElement>document.querySelector(".navCss nav")
    // if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    //   el.style.backgroundColor = "black";
    //   this.commonService.setState(false)
    // } else {
    //   document.getElementById("navid").style.backgroundColor = "transparent";
    //   el.style.backgroundColor = "transparent";
    //   this.commonService.setState(true)
    // }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
