import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ApplicationsComponent } from './pages/applications/applications.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProductComponent } from './components/product/product.component';
import { HomeComponent } from './pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CarbonFiberComponent } from './pages/carbon-fiber/carbon-fiber.component';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ProductInfoComponent } from './pages/product-info/product-info.component';
import { DailyProductComponent } from './components/daily-product/daily-product.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ApplicationsComponent,
    FooterComponent,
    ContactComponent,
    ProductComponent,
    HomeComponent,
    CarbonFiberComponent,
    ContactUsComponent,
    ProductInfoComponent,
    DailyProductComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
