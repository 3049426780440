<div class="carousel-container">
    <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'" *ngIf="product">
        <mdb-carousel-item *ngFor="let image of product.gallery">
            <div class="view w-100">
                <img class="d-block w-100" [src]="image.url" alt="First slide">
                <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
            </div>
            <div class="carousel-caption">
                <p>{{image.desc}}</p>
            </div>
        </mdb-carousel-item>

    </mdb-carousel>
</div>