import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {


  contacts = [
    {
      name: 'Ramesh Malu',
      position: 'Head and Guide',
      mobile: '',
      email: 'ramesh@malugroupindia.com',
      director: true
    },
    {
      name: 'Aashish Malu',
      position: 'Technical and marketing support',
      mobile: '+91-8793833689',
      email: 'aashish@malugroupindia.com'
    },
    {
      name: 'Sales',
      position: 'Technical and marketing support',
      mobile: '',
      email: 'sales@malugroupindia.com'
    }
  ]


  constructor() { }

  ngOnInit(): void {
  }

}
