<div *ngIf="product.description">
    <div class="container text-center text-md-left mb-5">

        <div >
            <div class="row mx-10 product-row wow animate zoomIn" >
                <div class="product-bg-image"> 
                    <img class="" [src]="product.icon" alt="">
                </div>
                <h1 class="product-title" >{{product.title}}</h1>
                <div class=" product-description "  [innerHtml]="product.description"> </div>
               
                <!-- <a class="gallery-link" [routerLink]="['/applications', product.id]">Gallery</a> -->
                <div class="gallery-link-wrapper" *ngIf="product.gallery && product.gallery.length>0">
                    <mdb-badge class="align-right" pill="true" default="true"><a class="gallery-link" [routerLink]="['/applications', product.id]">View Gallery</a></mdb-badge>
                </div>
               
            </div>
           
        </div>





    <!-- Grid row -->

</div>

</div>