import { Component, OnInit, Input, HostListener, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'daily-product',
  templateUrl: './daily-product.component.html',
  styleUrls: ['./daily-product.component.scss']
})
export class DailyProductComponent implements OnInit {

  @Input('image') image;
  @ViewChild('imageTag') imageRef
  show = false

  constructor(public el: ElementRef) { }


  @HostListener('mouseover')
  // mouseOver() {
  //   this.show = true
  //   this.imageRef.nativeElement.style.border = 'none'
  //   this.imageRef.nativeElement.style.boxShadow = 'none'
  // }

  // @HostListener('mouseout')
  // mouseOut() {
  //   this.show = false
  //   this.imageRef.nativeElement.style.border = '10px solid red'
  //   this.imageRef.nativeElement.style.boxShadow = '0px 0px 10px 5px red'
  // }

  ngOnInit(): void {
  }

}
