


<!--Navbar fixed-top scrolling-navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark " class="navCss" id="navid">

  <!-- Navbar brand -->
  <mdb-navbar-brand class="animated slideInLeft">
    <a class="navbar-brand" href="#">
      <img src="assets/images/icon.png" height="40" alt="malu group logo">
    </a>
  </mdb-navbar-brand>

  <!-- Collapsible content -->
  <links>

      <!-- Links -->
      <ul class="navbar-nav mr-auto">
          <li class="nav-item">
              <a class="nav-link waves-light" routerLink="/" mdbWavesEffect>Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link waves-light" routerLink="/carbonfiber" mdbWavesEffect>Cabon Fiber</a>
        </li>
          <li class="nav-item">
              <a class="nav-link waves-light" routerLink="/applications" mdbWavesEffect>Integrations and Applications</a>
          </li>
          <li class="nav-item">
              <a class="nav-link waves-light" routerLink="/contactus" mdbWavesEffect>Contact Us</a>
          </li>
      </ul>
      <!-- Links -->
  </links>
  <!-- Collapsible content -->

</mdb-navbar>