<div class="main-content">
    <div mdbScrollSpyElement="scrollspy1" id="integrations" class="section-title">Technical Applications</div>
    <hr>
    <div *ngFor="let product of products">
        <app-product [product]="product"></app-product>
    </div>


    <div style="background-color: white;">
        <div id="integrations" class="section-title">Applications</div>
        <hr>
        <section id="photos" class="container1">

            <daily-product image="https://malugroupindia.com/images/daily_applications/aerospace1.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/automotive.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/cases1.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/cover2.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/sports1.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/knife1.png"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/music2.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/pipes.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/sports4.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/aerospace2.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/music1.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/cases2.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/sports3.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/cover1.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/sports2.jpg"></daily-product>
            <daily-product image="https://malugroupindia.com/images/daily_applications/aerospace3.jpg"> </daily-product>
        </section>

    </div>
</div>