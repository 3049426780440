import { Component, OnInit, HostListener } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-main-content',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {

  products=[]

  constructor(public commonService: CommonService) {
    this.products = this.commonService.products
  }

  ngOnInit(): void {
  }

}
