import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-carbon-fiber',
  templateUrl: './carbon-fiber.component.html',
  styleUrls: ['./carbon-fiber.component.scss']
})
export class CarbonFiberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  
  }

}
